import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import MobileNav from './MobileNav';
import siteLinks from '../config/siteLinks';

import ActiveLogo from './logo';

const NavContainer = styled.header`
  display: ${props => (props.disable ? 'none' : null)};
`;

const NavWrap = styled.div`
  display: flex;
`;

const NavItemWrap = styled.div`
  padding: 0 5px;
  margin: 5px 0;
  border-left: ${props =>
    props.isFirst ? null : '1px solid rgba(0, 0, 0, 0.18)'};
  text-align: center;
`;

const NavItemText = styled.p`
  padding: 10px;
  margin: 0px;
  font-size: 14px;
  letter-spacing: 1.3px;
  color: #ce9d0a;
  font-weight: 900;
  text-transform: uppercase;
  transition: color 0.6s;
  &:hover {
    color: #ffcd38;
  }
`;

const DestopNavContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  background: white;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 900px) {
    display: none;
  }
`;

const DesktopNav = props => {
  return (
    <DestopNavContainer>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
          color: 'black',
        }}
      >
        <ActiveLogo />
      </Link>

      <NavWrap>
        {siteLinks.map((link, i) => {
          return (
            <NavItem key={'nav_' + i} src={link.path} isFirst={i === 0}>
              {link.name}
            </NavItem>
          );
        })}
      </NavWrap>
    </DestopNavContainer>
  );
};

const NavItem = ({ src, children, isFirst }) => {
  return (
    <NavItemWrap isFirst={isFirst}>
      <Link
        to={src}
        style={{
          textDecoration: `none`,
          color: 'black',
        }}
      >
        <NavItemText>{children}</NavItemText>
      </Link>
    </NavItemWrap>
  );
};

const Header = ({ siteTitle, color, disable }) => {
  let textColor = color ? color : 'black';
  return (
    <NavContainer disable={disable}>
      <DesktopNav />
      <MobileNav textColor={textColor} />
    </NavContainer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
