import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

const Container = styled.div`
  width: 100%;
  height: 60px;
  z-index: 1;
  display: ${props => (props.disable ? 'none' : null)};
  @media (max-width: 900px) {
    height: 45px;
  }
`;

const ContentWrap = styled(Container)`
  background-color: rgba(255, 205, 56, 1);
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;

  font-weight: 700;
  letter-spacing: 2px;
  color: 'black';
  &:hover {
    color: white;
  }
`;

const Content = styled.p`
  width: auto;
  margin: 0 auto;
  transition: color 0.3;
`;

export default function HelloBar({ disable }) {
  return (
    <Container disable={disable}>
      <Link
        to="/quiz"
        style={{
          textDecoration: `none`,
        }}
      >
        <ContentWrap>
          <Content>
            Are you charismatic? <Content>TAKE THE QUIZ!</Content>
          </Content>
        </ContentWrap>
      </Link>
    </Container>
  );
}
