/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Header from './header';
import './layout.css';

import HelloBar from './helloBar';

const ViewContainer = styled.div`
  margin: 0 auto;
  padding: 0px 20%;
  padding-top: 0;
  background: ${props => props.bgColor};
  min-height: calc(100vh - 50px);
  @media (max-width: 900px) {
    padding: 50px 15px;
  }
`;

const Layout = ({ children, bgColor = '#f5f5f5', disableHeader = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <HelloBar disable={disableHeader} />
      <Header
        disable={disableHeader}
        siteTitle={data.site.siteMetadata.title}
      />

      <ViewContainer bgColor={bgColor}>
        <main>{children}</main>
      </ViewContainer>
      <footer
        style={{
          margin: `0 auto`,
          padding: `30px 30px 15px`,
          background: bgColor,
          fontSize: '11px',
        }}
      >
        {data.site.siteMetadata.title} © {new Date().getFullYear()}
        <Link to="/terms-and-conditions" style={{ margin: '0 8px' }}>
          Terms and Conditions
        </Link>
        <Link to="/privacy">Privacy Policy</Link>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
