const siteLinks = [
  // { name: 'Get Started', path: '/get-started' },
  { name: 'Confidence', path: '/confidence' },
  { name: 'Charisma', path: '/charisma' },
  // { name: 'Style', path: '/style' },
  { name: 'Attraction', path: '/attraction' },
  { name: 'About', path: '/about' },
];

export default siteLinks;
