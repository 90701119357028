import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import siteLinks from '../../config/siteLinks';

import ActiveLogo from '../logo';

let HelloBarHeight = '45px';

const MobileNav = ({ textColor }) => {
  const [isMenuOpen, toggleMenu] = useState(false);

  return (
    <MobileContainer>
      <BurgerMenuToggle
        toggleMenu={toggleMenu}
        isMenuOpen={isMenuOpen}
        color={textColor}
      />
      <BurgerMenu isMenuOpen={isMenuOpen} />
      <Link
        style={{ textDecoration: 'none', color: textColor, marginLeft: '20px' }}
        to="/"
      >
        <ActiveLogo />
      </Link>
    </MobileContainer>
  );
};

const BurgerToggleContainer = styled.div`
  position: fixed;
  top: calc(20px + ${HelloBarHeight});
  right: 8%;
  z-index: 5;
  transition: all 0.3s;
  transform: ${props => (props.isOpen ? 'translateX(6px)' : null)};
`;
const burgerSectionHeight = 3;
const animationTime = 0.6;

const BurgerTop = styled.div`
  height: ${burgerSectionHeight}px;
  width: 80%;
  background: ${props => props.color};
  transform-origin: 0% 0%;
  transform: ${props =>
    props.isOpen ? 'rotate(45deg) translate(-3px, 0)' : null};
  transition: all ${animationTime}s;
`;
const BurgerMid = styled.div`
  height: ${burgerSectionHeight}px;
  width: 80%;
  margin-top: 6px;
  margin-bottom: 6px;
  background: ${props => props.color};
  opacity: ${props => (props.isOpen ? 0 : 1)};
  transition: all 0.3s;
`;

const BurgerBottom = styled.div`
  height: ${burgerSectionHeight}px;
  width: 80%;
  transform-origin: 0% 100%;
  transform: ${props =>
    props.isOpen ? 'rotate(-45deg)  translate(-3px, 0)' : null};
  background: ${props => props.color};
  transition: all ${animationTime}s;
`;

const BurgerMenuToggle = ({ isMenuOpen, toggleMenu, color }) => {
  const colorControl = isMenuOpen ? 'black' : color;
  return (
    <BurgerToggleContainer
      isOpen={isMenuOpen}
      onClick={() => {
        toggleMenu(!isMenuOpen);
      }}
    >
      <div id="burger-menu-toggle" style={{ width: '40px', height: '40px' }}>
        <BurgerTop isOpen={isMenuOpen} color={colorControl} />
        <BurgerMid isOpen={isMenuOpen} color={colorControl} />
        <BurgerBottom isOpen={isMenuOpen} color={colorControl} />
      </div>
    </BurgerToggleContainer>
  );
};

const BurgerMenuContainer = styled.div`
  z-index: 4;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  transition: all 0.2s;
`;

const MobileLinkContainer = styled.div`
  margin: 50px 0;
`;
const BurgerMenu = ({ isMenuOpen }) => {
  return (
    <BurgerMenuContainer isOpen={isMenuOpen}>
      <MobileLinksWrap>
        {siteLinks.map(link => {
          if (link.disabled) {
            return null;
          }
          return (
            <MobileLinkContainer>
              <MobileLinks path={link.path} name={link.name} />
            </MobileLinkContainer>
          );
        })}
      </MobileLinksWrap>
    </BurgerMenuContainer>
  );
};

const MobileContainer = styled.div`
  display: none;
  /* justify-content: space-between; */
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: white;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  height: 60px;
  position: fixed;
  top: ${HelloBarHeight};
  @media (max-width: 900px) {
    display: flex;
  }
`;

// const MobileLogo = styled.img`
//     width: 140px;
//     position: absolute;
//     left: 10%;
//     /* transform: translateX(-50%); */
//     top: 25px;
// `;

const MobileLinksWrap = styled.div`
  padding-left: 30px;
  padding-top: 20%;
`;

const MobileLinkContent = styled.p`
  padding: 5px;
  letter-spacing: 1.3px;
  color: #ce9d0a;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 20px;
`;

const MobileLinks = ({ path, name }) => {
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'black' }}>
      <MobileLinkContent>{name}</MobileLinkContent>
    </Link>
  );
};

export default MobileNav;
