import styled from 'styled-components';
import React from 'react';

const Logo = styled.h1`
  text-decoration: none;
  font-weight: 600;
`;

const ColorMap = styled.span`
  color: #f5bb0c;
`;

const ActiveLogo = () => (
  <Logo>
    <ColorMap>Suave</ColorMap>Way
  </Logo>
);

export default ActiveLogo;
